import { useState } from "react";
import "./index.css";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./Components/PrivateRoute";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import CreateUser from "./Pages/registroUser/createUser";
import RecoveryPassword from "./Pages/recuperacionContrasenaUser/index.jsx";
import LoadingPage from "./Pages/Login/LoadingPage";
import Logout from "./Pages/Login/logout";
import LoadingPageCreateUser from "./Pages/registroUser/LoadingPageCreateUser";
import PerfilUser from "./Pages/perfilUser/perfilUser";
import UserSuscription from "./Pages/perfilUser/SubscriptionInfo";
import MiPlan from "./Pages/miPlan/miPlan";
import CargandoPage from "./Pages/Global/cargandoPage";

function App() {
  // eslint-disable-next-line
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );

  function handleLogin(token) {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  }

  function handleLogout() {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  }

  return (
    <div>
      <main>
        <Routes>
          <Route index element={<Login onLogin={handleLogin} />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route
            path="/createuser"
            element={<CreateUser onRegister={handleLogin} />}
          />
          <Route path="/loading" element={<LoadingPage />} />
          <Route
            path="/recoveryPassword"
            element={<RecoveryPassword onRegister={handleLogin} />}
          />
          <Route path="/logout" element={<Logout onLogout={handleLogout} />} />

          <Route
            path="/create"
            element={<LoadingPageCreateUser handleLogout={handleLogout} />}
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute handleLogout={handleLogout}>
                <Dashboard handleLogout={handleLogout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/userMenu"
            element={
              <PrivateRoute>
                <PerfilUser handleLogout={handleLogout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/suscripcion"
            element={
              <PrivateRoute>
                <UserSuscription handleLogout={handleLogout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/miplandetrading"
            element={
              <PrivateRoute>
                <MiPlan handleLogout={handleLogout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/miPlan"
            element={
              <PrivateRoute>
                <MiPlan handleLogout={handleLogout} />
              </PrivateRoute>
            }
          />
          <Route
            path="/cargando"
            element={
              <PrivateRoute>
                <CargandoPage handleLogout={handleLogout} />
              </PrivateRoute>
            }
          />
        </Routes>
      </main>
    </div>
  );
}

export default App;
