import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  Typography,
  Divider,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const UpdatePassword = ({ token }) => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleClickShowPassword = (key) => {
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  };

  const handleClear = () => {
    setPasswordData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleSubmit = async () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert("Las contraseñas no coinciden. Por favor, inténtalo de nuevo.");
      return;
    }

    try {
      await axios.post(
        `https://www.jeferapp.dev/api/username/password`,
        passwordData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Contraseña actualizada exitosamente.");
      handleClear();
    } catch (error) {
      console.error("Error al actualizar la contraseña:", error);
      alert(
        "Error al actualizar la contraseña. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <Box>
      <Box>
        <Typography mb="20px">Actualiza tu Contraseña</Typography>
      </Box>
      <Divider />
      <Grid container spacing={2} mt={2}>
        {Object.entries(passwordData).map(([key, value]) => (
          <Grid item xs={12} key={key}>
            <TextField
              type={showPassword[key] ? "text" : "password"}
              label={
                key === "currentPassword"
                  ? "Contraseña actual"
                  : key === "newPassword"
                  ? "Nueva contraseña"
                  : "Repetir nueva contraseña"
              }
              name={key}
              value={passwordData[key]}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClickShowPassword(key)}
                    >
                      {showPassword[key] ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: 2,
                mb: 2,
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 20, mt: 4, color: "#fff", fontWeight: 600 }}
            onClick={handleSubmit}
            fullWidth
          >
            Actualizar contraseña
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

UpdatePassword.propTypes = {
  token: PropTypes.string,
};

export default UpdatePassword;
