import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import "./index.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BABABA",
      borderRadius: 40,
    },
    "&:hover fieldset": {
      borderColor: "#1D9D8B",
    },
    "& .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
    "& .css-14jik6a-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
  },
});

function Login({ onLogin }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    password: "",
  });

  const validateFields = () => {
    const errors = {};

    // Validación de correo electrónico
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!emailRegex.test(email)) {
      errors.email = "Por favor, introduce un correo electrónico válido";
    }

    // Validación de contraseña
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      errors.password = "Ups, error en contraseña";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    try {
      const response = await axios.post("https://www.jeferapp.dev/api/login", {
        email: email,
        password: password,
      });
      const token = response.data.token;
      console.log("Token received:", token);
      localStorage.setItem("token", token);
      onLogin(token);
      navigate("/loading");
    } catch (error) {
      console.log("Error:", error);
      if (error.response) {
        if (error.response.status === 404) {
          setValidationErrors({
            ...validationErrors,
            email: "El usuario no existe",
          });
        } else if (error.response.status === 401) {
          setValidationErrors({
            ...validationErrors,
            password: "Contraseña incorrecta",
          });
        } else {
          setError("Error de servidor");
        }
      } else if (error.request) {
        setError("Error de red: no se pudo conectar con el servidor");
      } else {
        setError("Error inesperado");
      }
    }
  }

  return (
    <Box display="flex">
      <Box
        sx={{
          width: "55vw",
          height: "100vh",
        }}
        className="side_login"
      ></Box>
      <Box
        x={{
          width: "45vw",
          height: "100vh",
        }}
        className="container_login"
      >
        <div>
          <div>Logo</div>
          <Box position="relative" m="150px auto 0 auto">
            <Typography color="#000000" variant="h5" align="center">
              Iniciar Sesión
            </Typography>
            <Typography color="#000000" fontSize="14px" align="center">
              Utiliza tus datos para iniciar sesión:
            </Typography>
          </Box>

          <Box
            className="container_formlogin"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CssTextField
              name="email"
              label="¿Cuál es tu correo?"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              value={email}
              error={!!validationErrors.email}
              helperText={validationErrors.email ? validationErrors.email : " "}
              FormHelperTextProps={{ className: "helperText" }}
              InputLabelProps={{
                style: {
                  color: "#282828",
                },
              }}
              size="small"
              sx={{
                marginTop: 3,
              }}
              className="textField_login"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon style={{ color: "#BABABA" }} />
                  </InputAdornment>
                ),
              }}
            ></CssTextField>
            <CssTextField
              name="password"
              label="Contraseña"
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="textField_login"
              error={!!validationErrors.password}
              helperText={
                validationErrors.password ? validationErrors.password : " "
              }
              InputLabelProps={{
                style: {
                  color: "#282828",
                },
              }}
              size="small"
              sx={{
                marginTop: 1,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <PasswordOutlinedIcon style={{ color: "#BABABA" }} />
                  </InputAdornment>
                ),
              }}
            ></CssTextField>

            <button type="submit" className="button_login">
              Iniciar Sesión
            </button>
            {error && (
              <Typography mt="20px" style={{ color: "red" }}>
                {error}
              </Typography>
            )}
          </Box>
          <Box marginTop="20px">
            <Link
              to="/recoveryPassword"
              style={{
                color: "#FF544A",
                fontSize: "12px",
              }}
            >
              <Typography align="right" fontSize="14px">
                ¿Olvidaste tu contraseña?
              </Typography>
            </Link>
          </Box>

          <Box
            marginTop="70px"
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography color="#000000" variant="h6" align="center">
              ¿Aún sin Usuario?
            </Typography>
            <Typography color="#000000" fontSize="14px" align="center">
              Registrate aqui:
            </Typography>
            <Link to="/createuser">
              <button className="button_linkregister">Crear mi Usuario</button>
            </Link>
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default Login;
