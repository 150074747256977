import React, { useState, useEffect } from "react";
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  TextField,
  IconButton,
  Typography,
  FormGroup,
  FormControl,
} from "@mui/material";
import "./index.css";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import "./index.css";
import { OutlinedInput } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro/SingleInputTimeRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { styled } from "@mui/material/styles";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import TimezoneSelect from "react-timezone-select";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BABABA",
      borderRadius: 40,
    },
    "&:hover fieldset": {
      borderColor: "#1D9D8B",
    },
    "& .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
    "& .css-myb2s4-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
    "& .css-14jik6a-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
  },
});

export const MultiStepForm = () => {
  // Control de Estados:

  // Form
  const [activeStep, setActiveStep] = useState(0);

  // Step 1

  const [capitalInicial, setCapitalInicial] = useState("");
  const [datePicker, setDatePicker] = useState("");

  // Step 2

  const [dailyRisk, setDailyRisk] = useState("");
  const [dailyGoal, setDailyGoal] = useState("");
  const [weeklyRisk, setWeeklyRisk] = useState("");
  const [weeklyGoal, setWeeklyGoal] = useState("");
  const [tradesPerDay, setTradesPerDay] = useState("");

  // Step 3

  const [checkedDivisas, setCheckedDivisas] = useState(false);
  const [checkedAcciones, setCheckedAcciones] = useState(false);
  const [checkedCriptomonedas, setCheckedCriptomonedas] = useState(false);
  const [checkedIndices, setCheckedIndices] = useState(false);
  const [checkedMaterias, setCheckedMaterias] = useState(false);
  const [checkedOtros, setCheckedOtros] = useState(false);

  const [porcentajeDivisas, setPorcentajeDivisas] = useState(0);
  const [porcentajeAcciones, setPorcentajeAcciones] = useState(0);
  const [porcentajeCriptos, setPorcentajeCriptos] = useState(0);
  const [porcentajeIndices, setPorcentajeIndices] = useState(0);
  const [porcentajeMaterias, setPorcentajeMaterias] = useState(0);
  const [porcentajeOtros, setPorcentajeOtros] = useState(0);

  // step 4 quitado
  // eslint-disable-next-line
  const [activosAgregados, setActivosAgregados] = useState([""]);

  // Step 5

  const [horarioTrading, setHorarioTrading] = useState(() => [
    dayjs("2022-04-17T15:30"),
    dayjs("2022-04-17T18:30"),
  ]);

  const [zonaHoraria, setZonaHoraria] = useState({ value: "" });

  //Eventos Multiform

  // Continuar al siguiente paso
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Regresar al paso anterior
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Validar y enviar el formulario

  function getUserIdFromToken() {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }

    try {
      const decoded = jwtDecode(token);

      return decoded.id;
    } catch (error) {
      console.error("Error al decodificar el token:", error);
      return null;
    }
  }
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userIdFromToken = getUserIdFromToken();
    if (userIdFromToken) {
      setId(userIdFromToken);
    }
  }, []);

  const submitForm = () => {
    const onSubmitSuccess = () => {};

    handleData(
      "POST",
      {
        capitalInicial,
        datePicker,
        dailyRisk,
        dailyGoal,
        weeklyRisk,
        weeklyGoal,
        tradesPerDay,
        checkedDivisas,
        checkedAcciones,
        checkedCriptomonedas,
        checkedIndices,
        checkedMaterias,
        checkedOtros,
        porcentajeDivisas,
        porcentajeAcciones,
        porcentajeCriptos,
        porcentajeIndices,
        porcentajeMaterias,
        porcentajeOtros,
        activosAgregados,
        horarioTrading,
        userId: id, // Utiliza 'id' en lugar de 'userId'
      },
      onSubmitSuccess
    );
  };

  const handleData = async (method, data, onSubmitSuccess) => {
    const authToken = localStorage.getItem("token"); // Leer el token del localStorage
    if (!authToken) {
      console.log("Token no encontrado");
      return;
    }

    const id = getUserIdFromToken();
    if (!id) {
      console.log("No se pudo obtener el userId del token");
      return;
    }

    try {
      const response = await axios({
        method,
        url: "https://www.jeferapp.dev/trplan",
        data,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response && response.data) {
        switch (method) {
          case "POST":
            onSubmitSuccess(response.data);
            navigate("/cargando");
            break;
          default:
            setError("Método no soportado");
        }
      } else {
        setError("Error desconocido durante la petición");
      }
    } catch (error) {
      console.error("Error:", error);
      console.error("Response data:", error.response?.data);
      console.error("Validation errors:", error.response?.data?.errors);
      if (error.response?.data?.errors) {
        error.response.data.errors.forEach((err, index) => {
          console.error(`Error ${index + 1}:`, err);
        });
      }
      setError(error.response?.data?.message || "Error durante la petición");
    }
  };

  //Eventos Steps

  // Eventos Step 1

  const handleCapitalIncicialChange = (event) => {
    setCapitalInicial(event.target.value);
  };

  // Eventos Steps 2

  const handleDailyRiskChange = (event) => {
    setDailyRisk(event.target.value);
  };

  const handleDailyGoalChange = (event) => {
    setDailyGoal(event.target.value);
  };

  const handleWeeklyRiskChange = (event) => {
    setWeeklyRisk(event.target.value);
  };

  const handleWeeklyGoalChange = (event) => {
    setWeeklyGoal(event.target.value);
  };

  const handleTradesPerDayChange = (event) => {
    setTradesPerDay(event.target.value);
  };

  // Eventos Step 3

  const handleChangeDivisas = (event) => {
    const isChecked = event.target.checked;
    setCheckedDivisas(isChecked);

    if (!isChecked) {
      setPorcentajeDivisas(0);
    }
  };

  const handleChangeAcciones = (event) => {
    const isChecked = event.target.checked;
    setCheckedAcciones(isChecked);

    if (!isChecked) {
      setPorcentajeAcciones(0);
    }
  };

  const handleChangeCriptos = (event) => {
    const isChecked = event.target.checked;
    setCheckedCriptomonedas(isChecked);

    if (!isChecked) {
      setPorcentajeCriptos(0);
    }
  };

  const handleChangeIndices = (event) => {
    const isChecked = event.target.checked;
    setCheckedIndices(isChecked);

    if (!isChecked) {
      setPorcentajeIndices(0);
    }
  };

  const handleChangeMaterias = (event) => {
    const isChecked = event.target.checked;
    setCheckedMaterias(isChecked);

    if (!isChecked) {
      setPorcentajeMaterias(0);
    }
  };

  const handleChangeOtros = (event) => {
    const isChecked = event.target.checked;
    setCheckedOtros(isChecked);

    if (!isChecked) {
      setPorcentajeOtros(0);
    }
  };

  function handlePorcentajeDivisasChange(event) {
    if (checkedDivisas) {
      const value = event.target.value;
      if (value === "") {
        setPorcentajeDivisas(0);
      } else if (/^\d*\.?\d*$/.test(value)) {
        const intValue = parseInt(value);
        setPorcentajeDivisas(intValue);
      }
    }
  }

  function handlePorcentajeAccionesChange(event) {
    if (checkedAcciones) {
      const value = event.target.value;
      if (value === "") {
        setPorcentajeAcciones(0);
      } else if (/^\d*\.?\d*$/.test(value)) {
        const intValue = parseInt(value);
        setPorcentajeAcciones(intValue);
      }
    }
  }

  function handlePorcentajeCriptosChange(event) {
    if (checkedCriptomonedas) {
      const value = event.target.value;
      if (value === "") {
        setPorcentajeCriptos(0);
      } else if (/^\d*\.?\d*$/.test(value)) {
        const intValue = parseInt(value);
        setPorcentajeCriptos(intValue);
      }
    }
  }

  function handlePorcentajeIndicesChange(event) {
    if (checkedIndices) {
      const value = event.target.value;
      if (value === "") {
        setPorcentajeIndices(0);
      } else if (/^\d*\.?\d*$/.test(value)) {
        const intValue = parseInt(value);
        setPorcentajeIndices(intValue);
      }
    }
  }

  function handlePorcentajeMateriasChange(event) {
    if (checkedMaterias) {
      const value = event.target.value;
      if (value === "") {
        setPorcentajeMaterias(0);
      } else if (/^\d*\.?\d*$/.test(value)) {
        const intValue = parseInt(value);
        setPorcentajeMaterias(intValue);
      }
    }
  }

  function handlePorcentajeOtrosChange(event) {
    if (checkedOtros) {
      const value = event.target.value;
      if (value === "") {
        setPorcentajeOtros(0);
      } else if (/^\d*\.?\d*$/.test(value)) {
        const intValue = parseInt(value);
        setPorcentajeOtros(intValue);
      }
    }
  }

  // Eventos Step 4
  const handleTimezoneChange = (selectedTimezone) => {
    if (selectedTimezone) {
      setZonaHoraria(selectedTimezone);
    }
  };

  //Eventos Matematicos

  const riesgoDiaroMonto = (capitalInicial * dailyRisk) / 100;
  const targetDiarioMonto = (capitalInicial * dailyGoal) / 100;
  const riesgoSemanalMonto = (capitalInicial * weeklyRisk) / 100;
  const targetSemanalMonto = (capitalInicial * weeklyGoal) / 100;
  const riesgoPorTrade = dailyRisk / tradesPerDay;
  const riesgoPorTradeMonto = riesgoDiaroMonto / tradesPerDay;
  const targetPorTrade = dailyGoal / tradesPerDay;
  const targetPorTradeMonto = targetDiarioMonto / tradesPerDay;
  const porcentajeCapitalPorActivoDivisas =
    (capitalInicial * porcentajeDivisas) / 100;
  const porcentajeCapitalPorActivoAcciones =
    (capitalInicial * porcentajeAcciones) / 100;
  const porcentajeCapitalPorActivoCriptos =
    (capitalInicial * porcentajeCriptos) / 100;
  const porcentajeCapitalPorActivoIndices =
    (capitalInicial * porcentajeIndices) / 100;
  const porcentajeCapitalPorActivoMaterias =
    (capitalInicial * porcentajeMaterias) / 100;
  const porcentajeCapitalPorActivoOtros =
    (capitalInicial * porcentajeOtros) / 100;

  return (
    <Box className="box_principal">
      <Box className="box_steps">
        <Stepper activeStep={activeStep}>
          <Step key="step-1">
            <StepLabel></StepLabel>
          </Step>
          <Step key="step-2">
            <StepLabel></StepLabel>
          </Step>
          <Step key="step-3">
            <StepLabel></StepLabel>
          </Step>
          <Step key="step-4">
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              margin="0 auto 0 auto"
              width="95%"
              height="50px"
              sx={{
                borderBottom: "2px solid rgba(179, 182, 186, 0.22)",
              }}
            >
              <Typography fontSize="18px" fontWeight="bold" mt="20px">
                Crea tu Plan de Trading:
              </Typography>
              <Typography fontSize="18px" mt="20px">
                Gestión de Riesgo y Capitales
              </Typography>
            </Box>
            <Typography variant="h5" m="3% 0 0 3%">
              Comencemos, cuentame:
            </Typography>
          </Box>
          <Box className="box_firts">
            <Typography m="50px auto 25px 0" sx={{ width: "400px" }}>
              ¿Cuál es tu Capital Inicial?
            </Typography>
            <FormControl sx={{ m: 2 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Ingresa tu Capital
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Ingresa tu Capital"
                value={capitalInicial}
                onChange={handleCapitalIncicialChange}
              />
            </FormControl>
            <Box
              display="flex"
              justifyContent="space-evenly"
              className="containerDate"
            >
              <Typography mt="30px">Elige una fecha para comenzar:</Typography>
              <Box mt="18px" height="70px">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker value={datePicker} onChange={setDatePicker} />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {activeStep === 1 && (
        <>
          <Box className="containerSteps">
            <Box
              display="flex"
              justifyContent="space-between"
              height="50px"
              sx={{
                borderBottom: "2px solid rgba(179, 182, 186, 0.22)",
              }}
            >
              <Typography fontSize="18px" fontWeight="bold" mt="20px">
                Crea tu Plan de Trading:
              </Typography>
              <Typography fontSize="18px" mt="20px">
                Gestión de Riesgo y Capitales
              </Typography>
            </Box>
            <Typography m="30px 0 0 5px">
              Determina tus Riesgos y Objetivos:
            </Typography>
            <Box className="container_gestion">
              <Box
                position="absolute"
                m="20px 0 0 5px"
                className="container_riesgo"
              >
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  marginTop="20px"
                >
                  <Typography mt="20px">Riesgo por Día:</Typography>
                  <FormControl sx={{ m: 1, width: "200px" }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "porcentaje",
                      }}
                      value={dailyRisk}
                      onChange={handleDailyRiskChange}
                    />
                  </FormControl>
                </Box>

                <Box display="flex" justifyContent="space-evenly">
                  <Typography mt="20px">Objetivo por Día:</Typography>
                  <FormControl sx={{ m: 1, width: "200px" }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "porcentaje",
                      }}
                      value={dailyGoal}
                      onChange={handleDailyGoalChange}
                    />
                  </FormControl>
                </Box>

                <Box display="flex" justifyContent="space-evenly">
                  <Typography mt="20px">Riesgo Semanal:</Typography>
                  <FormControl sx={{ m: 1, width: "200px" }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "porcentaje",
                      }}
                      value={weeklyRisk}
                      onChange={handleWeeklyRiskChange}
                    />
                  </FormControl>
                </Box>

                <Box display="flex" justifyContent="space-evenly">
                  <Typography mt="20px">Objetivo Semanal:</Typography>
                  <FormControl sx={{ m: 1, width: "200px" }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "porcentaje",
                      }}
                      value={weeklyGoal}
                      onChange={handleWeeklyGoalChange}
                    />
                  </FormControl>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  m="8px 0 20px 0"
                >
                  <Typography m="20px 45px 0 -15px">
                    Nro Trades por día:
                  </Typography>
                  <TextField
                    id="outlined-number"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: "140px",
                    }}
                    value={tradesPerDay}
                    onChange={handleTradesPerDayChange}
                  />
                </Box>
              </Box>

              <Box>
                <table class="table_resumen--createplan02">
                  <Typography
                    display="flex"
                    justifyContent="space-around"
                    m="-20px auto 20px auto"
                  >
                    Resumen
                  </Typography>
                  <tbody>
                    <tr>
                      <td>Riesgo por día:</td>
                      <td>{dailyRisk}%</td>
                      <td>$ {riesgoDiaroMonto}</td>
                    </tr>
                    <tr>
                      <td>Objetivo por día:</td>
                      <td>{dailyGoal}%</td>
                      <td>$ {targetDiarioMonto}</td>
                    </tr>
                    <tr>
                      <td>Riesgo por semana:</td>
                      <td>{weeklyRisk}%</td>
                      <td>$ {riesgoSemanalMonto}</td>
                    </tr>
                    <tr>
                      <td>Objetivo por semana:</td>
                      <td>{weeklyGoal}%</td>
                      <td>$ {targetSemanalMonto}</td>
                    </tr>
                  </tbody>
                </table>
              </Box>

              <Box class="container_tradesxdays">
                <Typography m="20px 0 0 20px" fontSize="14px" fontWeight="bold">
                  Trades por día:
                </Typography>
                <Typography m="-30px 10px 0 30px" fontSize="80px">
                  {tradesPerDay}
                </Typography>
                <table>
                  <tbody>
                    <tr>
                      <td>Riesgo por Trade:</td>
                    </tr>
                    <tr>
                      <td>{riesgoPorTrade.toFixed(2)}%</td>
                      <td>$ {riesgoPorTradeMonto.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Objetivo por Trade:</td>
                    </tr>
                    <tr>
                      <td>{targetPorTrade.toFixed(2)}%</td>
                      <td>$ {targetPorTradeMonto.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {activeStep === 2 && (
        <>
          <Box className="containerSteps">
            <Box
              display="flex"
              justifyContent="space-between"
              height="50px"
              sx={{
                borderBottom: "2px solid rgba(179, 182, 186, 0.22)",
              }}
            >
              <Typography fontSize="18px" fontWeight="bold" mt="20px">
                Crea tu Plan de Trading:
              </Typography>
              <Typography fontSize="18px" mt="20px">
                Mercados
              </Typography>
            </Box>

            <Box className="container_mercadosForm">
              <Box>
                <Box>
                  <Typography mt="20px">Selecciona los Mercados:</Typography>
                  <Box
                    width="200px"
                    height="90%"
                    mt="20px"
                    display="flex"
                    flexDirection="column"
                    justifyItems="space-around"
                  >
                    <FormGroup>
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={checkedDivisas}
                            onChange={handleChangeDivisas}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Divisas - FOREX"
                        labelPlacement="end"
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={checkedAcciones}
                            onChange={handleChangeAcciones}
                          />
                        }
                        label="Acciones"
                        labelPlacement="end"
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={checkedCriptomonedas}
                            onChange={handleChangeCriptos}
                          />
                        }
                        label="Criptomonedas"
                        labelPlacement="end"
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={checkedIndices}
                            onChange={handleChangeIndices}
                          />
                        }
                        label="Indices Bursatiles"
                        labelPlacement="end"
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={checkedMaterias}
                            onChange={handleChangeMaterias}
                          />
                        }
                        label="Materias Primas"
                        labelPlacement="end"
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={checkedOtros}
                            onChange={handleChangeOtros}
                          />
                        }
                        label="Otros"
                        labelPlacement="end"
                        sx={{ mt: 2, mb: 2 }}
                      />
                    </FormGroup>
                  </Box>
                </Box>
              </Box>

              <Box>
                <Typography display="flex" textAlign="center" mt={3}>
                  % de Capital para cada Mercado:
                </Typography>
                <Box ml={2}>
                  <FormControl sx={{ mt: 2, width: "18ch" }} variant="outlined">
                    <CssTextField
                      name="Divisas"
                      value={porcentajeDivisas}
                      onChange={handlePorcentajeDivisasChange}
                      disabled={!checkedDivisas}
                      required
                      InputLabelProps={{
                        style: {
                          color: "#282828",
                        },
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "#BABABA" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      helperText={`$${porcentajeCapitalPorActivoDivisas}`}
                    />
                  </FormControl>
                  <FormControl sx={{ mt: 2, width: "18ch" }} variant="outlined">
                    <CssTextField
                      name="Acciones"
                      value={porcentajeAcciones}
                      onChange={handlePorcentajeAccionesChange}
                      disabled={!checkedAcciones}
                      required
                      InputLabelProps={{
                        style: {
                          color: "#282828",
                        },
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "#BABABA" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      helperText={`$${porcentajeCapitalPorActivoAcciones}`}
                    />{" "}
                  </FormControl>
                  <FormControl sx={{ mt: 1, width: "18ch" }} variant="outlined">
                    <CssTextField
                      name="Criptomonedas"
                      value={porcentajeCriptos}
                      onChange={handlePorcentajeCriptosChange}
                      disabled={!checkedCriptomonedas}
                      required
                      InputLabelProps={{
                        style: {
                          color: "#282828",
                        },
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "#BABABA" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      helperText={`$${porcentajeCapitalPorActivoCriptos}`}
                    />{" "}
                  </FormControl>
                  <FormControl sx={{ mt: 1, width: "18ch" }} variant="outlined">
                    <CssTextField
                      name="Indices"
                      value={porcentajeIndices}
                      onChange={handlePorcentajeIndicesChange}
                      disabled={!checkedIndices}
                      required
                      InputLabelProps={{
                        style: {
                          color: "#282828",
                        },
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "#BABABA" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      helperText={`$${porcentajeCapitalPorActivoIndices}`}
                    />{" "}
                  </FormControl>
                  <FormControl sx={{ mt: 2, width: "18ch" }} variant="outlined">
                    <CssTextField
                      name="Materias"
                      value={porcentajeMaterias}
                      onChange={handlePorcentajeMateriasChange}
                      disabled={!checkedMaterias}
                      required
                      InputLabelProps={{
                        style: {
                          color: "#282828",
                        },
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "#BABABA" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      helperText={`$${porcentajeCapitalPorActivoMaterias}`}
                    />{" "}
                  </FormControl>
                  <FormControl sx={{ mt: 1, width: "18ch" }} variant="outlined">
                    <CssTextField
                      name="Otros"
                      value={porcentajeOtros}
                      onChange={handlePorcentajeOtrosChange}
                      disabled={!checkedOtros}
                      required
                      InputLabelProps={{
                        style: {
                          color: "#282828",
                        },
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "#BABABA" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      helperText={`$${porcentajeCapitalPorActivoOtros}`}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className="container_piechartForm">
                <Chart
                  type="pie"
                  width={360}
                  height={450}
                  series={[
                    porcentajeDivisas,
                    porcentajeAcciones,
                    porcentajeCriptos,
                    porcentajeIndices,
                    porcentajeMaterias,
                    porcentajeOtros,
                  ]}
                  options={{
                    labels: [
                      "Divisas",
                      "Acciones",
                      "Criptos",
                      "Indices",
                      "Comodities",
                      "Otros",
                    ],
                    colors: [
                      "#167F70",
                      "#1D9D8B",
                      "#0EC641",
                      "#4EFF7F",
                      "#073815",
                      "#073840",
                    ],
                    legend: { position: "top" },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}

      {activeStep === 3 && (
        <>
          <Box className="containerSteps">
            <Box
              display="flex"
              justifyContent="space-between"
              height="50px"
              sx={{
                borderBottom: "2px solid rgba(179, 182, 186, 0.22)",
              }}
            >
              <Typography fontSize="18px" fontWeight="bold" mt="20px">
                Crea tu Plan de Trading:
              </Typography>
              <Typography fontSize="18px" mt="20px">
                Horario
              </Typography>
            </Box>
            <Box>
              <Typography m="20px 0 0 20px">
                ¿En cuál horario operas?
              </Typography>
              <Box width="710px" m="20px 0 0 20px">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      "SingleInputTimeRangeField",
                      "SingleInputTimeRangeField",
                    ]}
                  >
                    <SingleInputTimeRangeField
                      label="Elige hora de inicio y final"
                      value={horarioTrading}
                      onChange={(newValue) => setHorarioTrading(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box m="40px 20px 0 20px">
                <Typography>Selecciona tu Zona Horaria:</Typography>

                <div className="select-wrapper">
                  <TimezoneSelect
                    value={zonaHoraria}
                    onChange={handleTimezoneChange}
                    placeholder="Selecciona tu zona horaria..."
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        className="box_iconsprogress"
      >
        <IconButton disabled={activeStep === 0} onClick={handleBack}>
          <ArrowBackIosRoundedIcon sx={{ color: "#9033FF", fontSize: 50 }} />
        </IconButton>
        {activeStep < 3 ? (
          <IconButton onClick={handleNext}>
            <ArrowForwardIosRoundedIcon
              sx={{ color: "#9033FF", fontSize: 50 }}
            />
          </IconButton>
        ) : (
          <IconButton onClick={submitForm} type="submit">
            <CheckCircleRoundedIcon
              sx={{
                color: "#9033ff",
                fontSize: 50,
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default MultiStepForm;
