import React from "react";

import { Box, Typography } from "@mui/material";

const SubscriptionInfo = () => {
  return (
    <Box>
      <Box>
        <Typography mb="20px">Gestion de Suscripción</Typography>
      </Box>
    </Box>
  );
};

export default SubscriptionInfo;
