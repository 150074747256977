import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, IconButton, CssBaseline } from "@mui/material";
import "./topNav.css";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import UserImg from "../../../Components/Assets/userImg.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Item = ({ title, to, onClick }) => (
  <Link to={to} className="custom-link" onClick={onClick}>
    {title}
  </Link>
);

const TopNav = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userName, setUserName] = useState(null);

  const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token") || null;
  };

  const token = getTokenFromLocalStorage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://www.jeferapp.dev/api/username/name`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setUserName(response.data.name);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      className="topnav"
      alignItems="center"
    >
      <CssBaseline />

      <Box width="70%" mt="10px" display="flex">
        <Typography variant="h6" mt="7px" ml={2}>
          Bienvenido {userName}
        </Typography>
      </Box>

      {/* Iconos */}
      <Box display="flex">
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Tu Menu">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Jennifer Morales" src={UserImg} />
            </IconButton>
          </Tooltip>
          <Box backgroundColor="secondary">
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Item title="Ver mi Perfil" to="/userMenu" />
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopNav;
