import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import "./index.css";
import BackgrounSVG from "../../Components/Assets/background-register.svg";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BABABA",
      borderRadius: 40,
    },
    "&:hover fieldset": {
      borderColor: "#1D9D8B",
    },
    "& .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
    "& .css-14jik6a-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
  },
});

function RecoveryPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({ email: "" });

  const validateFields = () => {
    const errors = {};

    // Validación de correo electrónico
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!emailRegex.test(email)) {
      errors.email = "Por favor, introduce un correo electrónico válido";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    try {
      await axios.post("https://www.jeferapp.dev/api/recoveryPassword", {
        email,
      });
      navigate("/emailSent");
    } catch (error) {
      if (error.response.status === 404) {
        setValidationErrors({
          ...validationErrors,
          email: "El usuario no existe",
        });
      } else {
        setError("Error de servidor");
      }
    }
  }

  return (
    <Box sx={{ backgroundColor: "#FFFFFF" }}>
      <img src={BackgrounSVG} className="background_img" alt="background" />
      <Box display="flex" className="container_recovery">
        <Box
          sx={{ position: "relative", zIndex: 2 }}
          className="container_title"
        >
          <Typography
            variant="h5"
            display="flex"
            justifyContent="center"
            sx={{ color: "#272727" }}
          >
            Recuperar contraseña
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            sx={{ color: "#272727" }}
          >
            Por favor, ingresa tu correo electrónico:
          </Typography>
        </Box>

        <Box className="container_formrecovery">
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h6"
              display="flex"
              m="20px 0 0 20px"
              sx={{ color: "#272727" }}
            >
              ¿Cuál es tu correo?
            </Typography>
            <CssTextField
              name="email"
              label="Correo electrónico"
              type="email"
              onChange={handleChange}
              required
              value={email}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
              InputLabelProps={{
                style: {
                  color: "#282828",
                },
              }}
              size="small"
              className="textField_recovery"
              sx={{
                m: "15px 0 25px 25px",
                width: "85%",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon style={{ color: "#BABABA" }} />
                  </InputAdornment>
                ),
              }}
            ></CssTextField>
            <Typography
              display="flex"
              m="0 0 0 20px"
              sx={{ color: "#272727", width: "85%", fontSize: "12px" }}
            >
              *Te enviaremos a tu correo las instrucciones para recuperar tu
              contraseña y acceder rápidamente a tu campus.{" "}
            </Typography>
            <button
              type="submit"
              onClick={handleSubmit}
              className="button_recovery"
              cursor="pointer"
            >
              Enviar enlace de recuperación
            </button>
            {error && (
              <Typography mt="20px" style={{ color: "red" }}>
                {error}
              </Typography>
            )}
          </form>
          <Box m="60px 0 0 270px">
            <Link
              to="/"
              style={{
                color: "#FF544A",
                fontSize: "12px",
              }}
            >
              Volver al inicio de sesión
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RecoveryPassword;
