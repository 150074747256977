import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  TextField,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import userImg from "../../Components/Assets/userImg.png";

const PersonalInfo = ({ token }) => {
  const [infoRequired, setInfoRequired] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [editableRequired, setEditableRequired] = useState({
    name: false,
    email: false,
    phone: false,
  });

  const [infoPlus, setInfoPlus] = useState({
    address: "",
  });

  const [editable, setEditable] = useState({
    address: false,
  });

  const fieldLabels = {
    name: "Nombre",
    email: "Correo electrónico",
    phone: "WhatsApp",
    address: "Dirección",
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;

      const fieldNamesRequired = ["name", "email", "phone"];
      const fieldNamesPlus = ["address"];
      const newInfoRequired = { ...infoRequired };
      const newInfoPlus = { ...infoPlus };

      for (const fieldName of fieldNamesRequired) {
        try {
          const response = await axios.get(
            `https://www.jeferapp.dev/api/username/${fieldName}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          newInfoRequired[fieldName] = response.data[fieldName];
        } catch (error) {
          console.error(`Error al obtener ${fieldName}:`, error);
        }
      }

      setInfoRequired(newInfoRequired);

      for (const fieldName of fieldNamesPlus) {
        try {
          const response = await axios.get(
            `https://www.jeferapp.dev/api/username/${fieldName}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          newInfoPlus[fieldName] = response.data[fieldName];
        } catch (error) {
          console.error(`Error al obtener ${fieldName}:`, error);
        }
      }

      setInfoPlus(newInfoPlus);
    };

    fetchData();
  }, [token, infoPlus, infoRequired]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in infoRequired) {
      setInfoRequired({ ...infoRequired, [name]: value });
    } else {
      setInfoPlus({ ...infoPlus, [name]: value });
    }
  };

  const handleEditClick = (fieldName) => {
    if (fieldName in editableRequired) {
      setEditableRequired({
        ...editableRequired,
        [fieldName]: !editableRequired[fieldName],
      });
    } else {
      setEditable({ ...editable, [fieldName]: !editable[fieldName] });
    }
  };

  const handleSubmit = async () => {
    const updateField = async (fieldName, newValue) => {
      try {
        await axios.put(
          `https://www.jeferapp.dev/api/username/${fieldName}`,
          { newValue },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.error(`Error al actualizar ${fieldName}:`, error);
      }
    };

    for (const [fieldName, newValue] of Object.entries(infoRequired)) {
      await updateField(fieldName, newValue);
    }

    for (const [fieldName, newValue] of Object.entries(infoPlus)) {
      await updateField(fieldName, newValue);
    }

    // Deshabilitar todos los campos después de la actualización
    setEditableRequired({ name: false, email: false, phone: false });
    setEditable({ address: false });
  };

  return (
    <Box>
      <Box>
        <Typography mb="20px">Información Personal</Typography>
      </Box>
      <Divider />
      <Box mb={4}>
        <Typography mt={1} mb={2} color="#ACB5BB">
          Foto de Perfil
        </Typography>
        <Box display="flex" mt={3}>
          <img src={userImg} alt="back" />
          <Button
            color="primary"
            borderRadius={20}
            variant="contained"
            sx={{
              color: "#fff",
              fontWeight: 600,
              ml: 2,
              mt: 1,
              height: "40px",
            }}
          >
            Subir Foto
          </Button>
          <Button>
            <DeleteForeverIcon fontSize="large" />
          </Button>
        </Box>
      </Box>
      <Divider />
      <Grid container spacing={2} mt={2}>
        {Object.entries(infoRequired).map(([key, value]) => (
          <Grid item xs={12} key={key}>
            <TextField
              label={fieldLabels[key]}
              name={key}
              value={infoRequired[key]}
              disabled={!editableRequired[key]}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => handleEditClick(key)}>
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: 2,
                mb: 2,
              }}
            />
          </Grid>
        ))}
        {Object.entries(infoPlus).map(([key, value]) => (
          <Grid item xs={12} key={key}>
            <TextField
              label={fieldLabels[key]}
              name={key}
              value={
                infoPlus[key] ? infoPlus[key] : " Puedes agregar este dato"
              }
              disabled={!editable[key]}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => handleEditClick(key)}>
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: 2,
                mb: 2,
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider />
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: 20,
              mt: 4,
              color: "#fff",
              fontWeight: 600,
            }}
            onClick={handleSubmit}
            fullWidth
          >
            Actualizar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

PersonalInfo.propTypes = {
  token: PropTypes.string,
};

export default PersonalInfo;
