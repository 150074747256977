import { Box } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import SideNav from "../Pages/Global/SideNav/SideNav";
import TopNav from "../Pages/Global/TopNav/TopNav";

function PrivateRoute({ children, handleLogout, ...rest }) {
  const isLoggedIn = localStorage.getItem("token") !== null;

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      {...rest}
      display="flex"
      flexDirection="row"
      overflow="hidden"
      position="absolute"
      width="100%"
      height="100vh"
    >
      <SideNav handleLogout={handleLogout} height="100vh" />
      <Box flex="1" display="flex" flexDirection="column">
        <TopNav />
        <Box flex="1" overflow="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default PrivateRoute;
