import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Header from "../../Components/Header";
import { Link, useNavigate } from "react-router-dom";
import "./perfilUser.css";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import PersonalInfo from "./PersonalInfo";
import SubscriptionInfo from "./SubscriptionInfo";
import SecurityUser from "./SecurityUser";
import PasswordRoundedIcon from "@mui/icons-material/PasswordRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

const PerfilUser = () => {
  const navigate = useNavigate();
  const [view, setView] = useState("personal");

  const getUserIdFromLocalStorage = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }

    return token;
  };

  const userToken = getUserIdFromLocalStorage();

  const getBreadcrumbsText = () => {
    switch (view) {
      case "personal":
        return "Información Personal";
      case "subscription":
        return "Mi Suscripción";
      case "seguridad":
        return "Seguridad";
      default:
        return "Información Personal";
    }
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={handleClick}
      className="custom-link"
    >
      Dashboard
    </Link>,
    <Typography key="3" color="text.primary">
      {getBreadcrumbsText()}
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    navigate("/dashboard");
  }

  return (
    <Box>
      <Box ml="20px">
        <div>
          <Header title="" breadcrumbs={breadcrumbs} />
        </div>
      </Box>

      <Box display="flex">
        <Box className="perfilUser">
          <Box>
            <div className="container_button">
              <div
                className={
                  view === "personal"
                    ? "container_buttonUserMenuSelect"
                    : "container_buttonUserMenu"
                }
                onClick={() => setView("personal")}
              >
                <div
                  className={
                    view === "personal"
                      ? "container_buttonUserMenu--iconSelect"
                      : "container_buttonUserMenu--icon"
                  }
                >
                  <PersonRoundedIcon />
                </div>
                <Typography>Información Personal</Typography>
                <KeyboardArrowRightRoundedIcon />
              </div>
              <div
                className={
                  view === "subscription"
                    ? "container_buttonUserMenuSelect"
                    : "container_buttonUserMenu"
                }
                onClick={() => setView("subscription")}
              >
                <div
                  className={
                    view === "subscription"
                      ? "container_buttonUserMenu--iconSelect"
                      : "container_buttonUserMenu--icon"
                  }
                >
                  <VerifiedUserRoundedIcon />
                </div>
                <Typography>Mi Suscripción</Typography>
                <KeyboardArrowRightRoundedIcon />
              </div>
              <div
                className={
                  view === "seguridad"
                    ? "container_buttonUserMenuSelect"
                    : "container_buttonUserMenu"
                }
                onClick={() => setView("seguridad")}
              >
                <div
                  className={
                    view === "seguridad"
                      ? "container_buttonUserMenu--iconSelect"
                      : "container_buttonUserMenu--icon"
                  }
                >
                  <PasswordRoundedIcon />
                </div>
                <Typography>Seguridad</Typography>
                <KeyboardArrowRightRoundedIcon />
              </div>
            </div>
          </Box>
        </Box>

        <Box className="perfilUser__details">
          {view === "personal" && <PersonalInfo token={userToken} />}
          {view === "subscription" && <SubscriptionInfo />}
          {view === "seguridad" && <SecurityUser token={userToken} />}
        </Box>
      </Box>
    </Box>
  );
};

export default PerfilUser;
