import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, IconButton, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import "./index.css";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import UserData from "../../Components/UserData";
import MultiStepForm from "../../Components/MultiStepsForm/index";

const MiPlan = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [hasTradingPlan, setHasTradingPlan] = useState(null);

  const getUserIdFromLocalStorage = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }
    return token;
  };

  const userToken = getUserIdFromLocalStorage();

  useEffect(() => {
    axios
      .get("https://www.jeferapp.dev/trplan", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        if (response.data && Object.keys(response.data).length > 0) {
          setHasTradingPlan(true);
        } else {
          setHasTradingPlan(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setHasTradingPlan(false);
        } else {
          console.error(
            "Error al verificar el plan de trading del usuario",
            error
          );
        }
      });
  }, [userToken]);

  return (
    <Box>
      <Header titulo="Crea tu plan de Trading" />
      <button onClick={() => setShowForm(!showForm)}>
        {hasTradingPlan ? "Modificar tu Plan" : "Crear tu Plan de Trading"}
      </button>
      {showForm && (
        <Box className="container_newplan">
          <Box className="container_createPlanTrading">
            {showFormCreate === false ? (
              <Box>
                <Box>
                  <Typography
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                    width={150}
                    variant="h5"
                  >
                    ¡Hola!
                    <UserData token={userToken} />
                  </Typography>
                  <Typography>Soy Jefer y seré tu asistente</Typography>
                  <Typography>
                    Para comenzar necesitamos configurar tu Plan de Trading
                  </Typography>
                  <Typography>¿Comenzamos?</Typography>
                </Box>
                <Button onClick={() => setShowFormCreate(!showFormCreate)}>
                  Si, ¡Comencemos!
                </Button>
              </Box>
            ) : (
              <Box className="formMultiSteps">
                <MultiStepForm />
              </Box>
            )}

            <Box className="link_dashboard" display="flex" alignItems="center">
              <Link
                style={{ color: "#1A1C1E", fontWeight: 400 }}
                onClick={() => {
                  setShowForm(false);
                  setShowFormCreate(false);
                }}
              >
                configurar luego (no recomendado)
              </Link>
              <IconButton
                style={{ color: "#9033FF" }}
                onClick={() => {
                  setShowForm(false);
                  setShowFormCreate(false);
                }}
              >
                <CancelRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MiPlan;
