import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout({ onLogout }) {
  const history = useNavigate();

  useEffect(() => {
    localStorage.removeItem("token");
    onLogout();
    history.push("/");
  }, [history, onLogout]);

  return <div>Cerrando sesión...</div>;
}

export default Logout;
