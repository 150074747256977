import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  TextField,
  IconButton,
  Typography,
  FormControl,
} from "@mui/material";
import "./index.css";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import BackgrounSVG from "../../Components/Assets/background-register.svg";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const initialFormState = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  whatsapp: "",
  termsAccepted: false,
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BABABA",
      borderRadius: 40,
    },
    "&:hover fieldset": {
      borderColor: "#1D9D8B",
    },
    "& .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
    "& .css-myb2s4-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
    "& .css-14jik6a-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "black",
    },
  },
});

export const CreateUser = ({ onRegister }) => {
  // Form
  const [activeStep, setActiveStep] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  //Eventos Multiform

  const navigate = useNavigate();
  const [setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [formState, setFormState] = useState(initialFormState);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const validateName = (name) => {
    return name.trim().length > 0;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateFields = () => {
    const errors = {};

    if (!validateName(formState.name)) {
      errors.name = "Por favor, introduce tu nombre";
    }

    if (!validateEmail(formState.email)) {
      errors.email = "Por favor, introduce un correo electrónico válido";
    }

    if (!validatePassword(formState.password)) {
      errors.password =
        "La contraseña debe tener al menos 8 caracteres, un caracter especial, un número, una letra mayúscula y una letra minúscula";
    }
    if (formState.password !== formState.confirmPassword) {
      errors.confirmPassword = "Las contraseñas no coinciden";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    try {
      const response = await axios.post(
        "https://www.jeferapp.dev/api/register",
        {
          name: formState.name,
          phone: phoneNumber,
          email: formState.email,
          password: formState.password,
        }
      );

      if (response && response.data && response.data.token) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        onRegister(token);
        navigate("/dashboard");
      } else {
        setError("Error desconocido durante el registro");
      }
    } catch (error) {
      console.error("Error:", error);
      console.error("Response data:", error.response?.data);
      console.error("Validation errors:", error.response?.data?.errors);
      if (error.response?.data?.errors) {
        error.response.data.errors.forEach((err, index) => {
          console.error(`Error ${index + 1}:`, err);
        });
      }
      setError(error.response?.data?.message || "Error durante el registro");
    }
  };

  const hasMinimumLength = (password) => {
    return password.length >= 8;
  };

  const hasSpecialCharacter = (password) => {
    const specialCharacterRegex = /[@$!%*?&]/;
    return specialCharacterRegex.test(password);
  };

  const hasNumber = (password) => {
    const numberRegex = /\d/;
    return numberRegex.test(password);
  };

  const hasUpperCase = (password) => {
    const upperCaseRegex = /[A-Z]/;
    return upperCaseRegex.test(password);
  };

  const hasLowerCase = (password) => {
    const lowerCaseRegex = /[a-z]/;
    return lowerCaseRegex.test(password);
  };

  const isStepValid = useCallback(() => {
    switch (activeStep) {
      case 0:
        return validateName(formState.name) && validateEmail(formState.email);
      case 1:
        if (
          validatePassword(formState.password) &&
          formState.password === formState.confirmPassword
        ) {
          setErrorMessage(""); // Si las contraseñas coinciden y son válidas, limpia cualquier error previo.
          return true;
        } else if (formState.password !== formState.confirmPassword) {
          setErrorMessage("Las contraseñas no coinciden.");
          return false;
        } else {
          setErrorMessage("crea una contraseña valida.");
          return false;
        }
      case 2:
        return formState.whatsapp !== "" && formState.termsAccepted;
      default:
        return false;
    }
  }, [activeStep, formState]);

  useEffect(() => {
    setIsNextButtonDisabled(!isStepValid());
  }, [formState, activeStep, isStepValid]);

  // Continuar al siguiente paso
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Regresar al paso anterior
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box className="container_register" sx={{ backgroundColor: "#FFFFFF" }}>
      <img src={BackgrounSVG} className="background_img" alt="background" />
      <Box mt={20}>
        <Box>
          <Typography
            display="flex"
            justifyContent="center"
            sx={{ color: "#272727" }}
          >
            JEFER
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            sx={{ color: "#272727" }}
          >
            Crea tu usuario. Es rápido y fácil.
          </Typography>
        </Box>
        <Box className="container_formregister">
          <Stepper activeStep={activeStep} className="container_steps">
            <Step key="step-1">
              <StepLabel></StepLabel>
            </Step>
            <Step key="step-2">
              <StepLabel></StepLabel>
            </Step>
            <Step key="step-3">
              <StepLabel></StepLabel>
            </Step>
          </Stepper>

          {activeStep === 0 && (
            <>
              <Box>
                <Typography
                  color="#000000"
                  mt="20px"
                  fontSize="12"
                  display="flex"
                  justifyContent="center"
                >
                  Primero cuentame:
                </Typography>
                <FormControl fullWidth>
                  <CssTextField
                    name="name"
                    label="¿Cómo te llamas?"
                    value={formState.name}
                    onChange={handleChange}
                    required
                    style={{ marginTop: 20 }}
                    className="textField"
                    InputLabelProps={{
                      style: {
                        color: "#282828",
                      },
                    }}
                    size="small"
                  />
                </FormControl>
                <br style={{ width: "400px", borderBottomColor: "#bababa" }} />
                <FormControl fullWidth>
                  <CssTextField
                    name="email"
                    label="Y ¿Cuál es tu correo?"
                    type="email"
                    value={formState.email}
                    onChange={handleChange}
                    required
                    error={!!validationErrors.email}
                    helperText={validationErrors.email}
                    style={{ marginTop: 20 }}
                    className="textField"
                    InputLabelProps={{
                      className: "input__text",
                      style: {
                        color: "#282828",
                      },
                    }}
                    size="small"
                  />
                </FormControl>
              </Box>
            </>
          )}

          {activeStep === 1 && (
            <>
              <Box className="container_formregister">
                <Typography
                  color="#000000"
                  mt="15px"
                  mb="10px"
                  fontSize="12"
                  display="flex"
                  justifyContent="center"
                >
                  Ahora crea tu Contraseña:
                </Typography>
                <FormControl fullWidth>
                  <CssTextField
                    name="password"
                    label="Crea tu Contraseña"
                    type="password"
                    value={formState.password}
                    onChange={handleChange}
                    className="textField"
                    required
                    error={!!validationErrors.password}
                    helperText={validationErrors.password}
                    inputProps={{
                      pattern:
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#282828",
                      },
                    }}
                    size="small"
                  />
                </FormControl>
                <Box display="flex">
                  <Box>
                    <Typography
                      mt={1}
                      ml={4}
                      fontSize={12}
                      color={
                        hasMinimumLength(formState.password)
                          ? "green"
                          : "#6C7278"
                      }
                    >
                      . minimo 8 characters
                    </Typography>
                    <Typography
                      mt={0}
                      ml={4}
                      fontSize={12}
                      color={
                        hasSpecialCharacter(formState.password)
                          ? "green"
                          : "#6C7278"
                      }
                    >
                      . Agrega un caracter especial
                    </Typography>
                    <Typography
                      mt={0}
                      ml={4}
                      fontSize={12}
                      color={
                        hasNumber(formState.password) ? "green" : "#6C7278"
                      }
                    >
                      . Tambien un número
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      mt={1}
                      ml={7}
                      fontSize={12}
                      color={
                        hasUpperCase(formState.password) ? "green" : "#6C7278"
                      }
                    >
                      . Una Letra Mayuscula
                    </Typography>
                    <Typography
                      mt={0}
                      ml={7}
                      fontSize={12}
                      color={
                        hasLowerCase(formState.password) ? "green" : "#6C7278"
                      }
                    >
                      . Y una letra Minuscula
                    </Typography>
                  </Box>
                </Box>
                <br />
                <FormControl>
                  <CssTextField
                    name="confirmPassword"
                    label="Confirma tu Contraseña"
                    type="password"
                    value={formState.confirmPassword}
                    onChange={handleChange}
                    className="textField"
                    required
                    error={!!validationErrors.confirmPassword}
                    helperText={validationErrors.confirmPassword}
                    InputLabelProps={{
                      style: {
                        color: "#282828",
                      },
                    }}
                    size="small"
                  />
                </FormControl>
                {errorMessage && (
                  <Typography
                    color="red"
                    fontSize="12px"
                    align="right"
                    margin="5px 40px 0 0"
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            </>
          )}

          {activeStep === 2 && (
            <>
              <Box className="container_formregister">
                <Typography
                  color="#000000"
                  mt="20px"
                  mb="20px"
                  fontSize="12"
                  display="flex"
                  justifyContent="center"
                >
                  Y finalmente ¿A cuál numero te contacto?:
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <FormControl className="phoneInput">
                    <PhoneInput
                      placeholder="Introduce tu número de teléfono"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                    />
                  </FormControl>

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.termsAccepted}
                          onChange={handleCheckboxChange}
                          name="termsAccepted"
                          color="primary"
                          required
                          sx={{
                            width: "25px",
                            height: "25px",
                            color: "#000000",
                          }}
                        />
                      }
                      label="Acepto los términos y condiciones"
                      sx={{ color: "#000000", margin: "20px 0 0 0" }}
                    />
                  </FormControl>
                </Box>
              </Box>
            </>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            className="icons_progress"
          >
            <IconButton disabled={activeStep === 0} onClick={handleBack}>
              <ArrowBackIosRoundedIcon
                sx={{ color: "#9033FF", fontSize: 30 }}
              />
            </IconButton>

            {activeStep < 2 ? (
              <IconButton
                onClick={handleNext}
                disabled={isNextButtonDisabled} // Usa el nuevo estado isNextButtonDisabled
              >
                <ArrowForwardIosRoundedIcon
                  sx={{
                    color: isNextButtonDisabled ? "#BABABA" : "#9033FF", // Usa el nuevo estado isNextButtonDisabled
                    fontSize: 30,
                  }}
                />
              </IconButton>
            ) : (
              <div className="Button_planready">
                <Button onClick={handleSubmit}>
                  <CheckCircleRoundedIcon
                    sx={{
                      color: "#9033ff",
                      fontSize: 50,
                      mr: "22px",
                    }}
                  />
                </Button>
              </div>
            )}
          </Box>
        </Box>{" "}
      </Box>
      <Box
        position="relative"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
        mt="400px"
        sx={{
          width: "420px",
          height: "50px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        zIndex={5}
      >
        <Typography sx={{ color: "#1A1C1E" }}>¿Ya Tienes Cuenta?</Typography>
        <Link to="/" style={{ color: "#1A1C1E", fontWeight: 400 }}>
          Inicia Sesión Aquí
        </Link>
      </Box>
    </Box>
  );
};

export default CreateUser;
