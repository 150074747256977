import React from "react";
import { useState } from "react";
import { Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./sidenav.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import Logout from "@mui/icons-material/Logout";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CssBaseline from "@mui/material/CssBaseline";
import clsx from "clsx";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";

const Item = ({ id, title, to, icon, selected, setSelected, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const hoverColor = hovered ? "#1A1C1E" : undefined;
  const hoverBackground = hovered ? "#FFFFFF" : undefined;

  return (
    <Link
      to={to}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ textDecoration: "none" }}
    >
      <MenuItem
        active={selected === id}
        onClick={(e) => {
          setSelected(id);
          if (onClick) onClick(e);
        }}
        icon={React.cloneElement(icon, { style: { color: hoverColor } })}
        style={{
          color: hoverColor,
          backgroundColor: hoverBackground,
          borderRadius:
            selected === title || hovered ? "50px 0 0 50px" : "50px 0 0 50px",
        }}
      >
        <Typography style={{ fontSize: "14px", color: hoverColor }}>
          {title}
        </Typography>
      </MenuItem>
    </Link>
  );
};

const SideNav = () => {
  const navigate = useNavigate();

  const onLogoutClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box>
      <CssBaseline />
      <Menu
        iconShape="square"
        className={clsx(isCollapsed ? "sidebar-collapsed" : "sidebar")}
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: active ? "#1A1C1E" : "#ffffff",
                backgroundColor: active ? "#ffffff" : "",
              };
          },
        }}
      >
        {/* LOGO AND MENU ICON */}
        <Box
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ cursor: "pointer", height: "60px", position: "relative" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Typography variant="h5" fontWeight="600" ml="30px" mt="38px">
              {isCollapsed ? "" : "JEFER"}
            </Typography>
            <Box
              position="absolute"
              top="50%"
              right="20px"
              transform="translateY(-50%)"
            >
              <IconButton
                onClick={() => setIsCollapsed(!isCollapsed)}
                style={{ cursor: "pointer", color: "#ffffff" }}
              >
                {isCollapsed ? <DoubleArrowRoundedIcon /> : <MenuOpenIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box mt="25px" ml="10px">
          <Item
            id="Dashboard"
            title={isCollapsed ? "" : "Dashboard"}
            to="/dashboard"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Typography margin="20px 0 10px 20px" fontWeight={600} fontSize={14}>
            {isCollapsed ? " " : "Trading"}
          </Typography>
          <Item
            id="Mi Plan de Trading"
            title={isCollapsed ? "" : "Mi Plan de Trading"}
            to="/miplandetrading"
            icon={<DesignServicesOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            id="Mi Bitácora"
            title={isCollapsed ? "" : "Mi Bitácora"}
            to="/miBitacora"
            icon={<TextSnippetOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            id="Optimización"
            title={isCollapsed ? "" : "Optimización"}
            to="/optimizacion"
            icon={<AutoGraphOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Typography margin="20px 0 10px 20px" fontWeight={600} fontSize={14}>
            {isCollapsed ? " " : "Herramientas"}
          </Typography>
          <Item
            id="Calculadoras"
            title={isCollapsed ? "" : "Calculadoras"}
            to="/calculadoras"
            icon={<CalculateOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            id="Radares"
            title={isCollapsed ? "" : "Radares"}
            to="/radares"
            icon={<TrackChangesOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          {/* LOGOUT */}
          <Box
            sx={{
              position: "absolute",
              bottom: "40px",
              maxWidth: "220px",
            }}
            width={isCollapsed ? "80px" : "210px"}
          >
            <Item
              id="Cerrar sesión"
              title={isCollapsed ? "" : "Cerrar sesión"}
              icon={<Logout />}
              selected={selected}
              setSelected={setSelected}
              onClick={(e) => onLogoutClick(e)}
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default SideNav;
