import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

function LoadingPageCreateUser() {
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("token"));

  useEffect(() => {
    async function fetchData() {
      // Llamada a la API
      const dataPromise = axios.get(
        `https://www.jeferapp.dev/api/username/name`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Creamos una promesa que se resolverá en 3 segundos
      const timerPromise = new Promise((resolve) => setTimeout(resolve, 3000));

      // Usamos Promise.all para esperar tanto la respuesta de la API como el temporizador
      Promise.all([dataPromise, timerPromise])
        .then((responses) => {
          // Una vez que ambos, los datos estén cargados y hayan pasado 3 segundos, navega al dashboard
          const result = responses[0].data.name;
          console.log("Nombre del usuario obtenido:", result);
          navigate("/createplan");
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
        });
    }

    fetchData();
  }, [navigate, token]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
      <Typography>Estoy creando tu Dashboard</Typography>
    </div>
  );
}

export default LoadingPageCreateUser;
