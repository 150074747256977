import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";

const Header = ({ title, subtitle }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box mb="10px">
        <Typography variant="h5" fontWeight="bold" sx={{ m: "0 0 4px 0" }}>
          {title}
        </Typography>
        <Typography variant="h6" sx={{ m: "0 0 0 2px" }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Header;
