import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

const UserData = ({ token }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseUserName = await axios.get(
          `https://www.jeferapp.dev/api/username/name`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const result = responseUserName.data.name;

        setResult(result);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <p>{result}</p>
    </div>
  );
};

UserData.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UserData;
